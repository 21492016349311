<template>
  <el-dialog v-model="photosShow"
             title=""
             width="45%"
             destroy-on-close >
    <div slot="header">
      <div class="fontSize20"
           style="padding-bottom: 20px;">现场照片</div>
    </div>
    <div class="store-image">
      <!-- 图片列表 -->
      <div v-for="(iter, index) in photos"
           :key="iter"
           class="store-image-item">
        <el-image fit="cover"
                  :src="iter"
                  :preview-src-list="photos"
                  :initial-index="index"
                  style="height: 100%;width: 100%;"></el-image>
      </div>
      <div v-if="photos.length === 0"
           style="width: 100%;"
           class="flex justify-center">
        <el-empty description="无现场照片" />
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="photosShow = false">关闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "ImageListShow",
  data(){
    return {
      photosShow: false,
      photos: [],
    }
  },
  methods: {
    setPhotos(img){
      this.photos = img
      this.photosShow = true
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-footer {
  padding-top: 20px;
  text-align: right;
  box-sizing: border-box;
}
.store-image{
  display: flex;
  flex-wrap: wrap;
  .store-image-item{
    margin-bottom: 5px;
    padding: 5px;
    width: 175px;
    height: 98px;
  }
}
</style>