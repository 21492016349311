import { h } from 'vue'
export default {
  name: 'ColumnContent',
  props: {
    row: {
      required: true
    },
    field: {
      required: true
    }
  },
  render(){
    if (this.field.render) {
      return this.field.render(this.row, h)
    }
    if (this.field.key) {
      return h('div', {
        attrs: {
          class: this.field.space ? this.field.space : 'nowrap'
        }
      }, this.row[this.field.key])
    }
  }
}